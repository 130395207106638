exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-filia-w-surazu-js": () => import("./../../../src/pages/filia-w-surazu.js" /* webpackChunkName: "component---src-pages-filia-w-surazu-js" */),
  "component---src-pages-gdzie-zrobic-badania-js": () => import("./../../../src/pages/gdzie-zrobic-badania.js" /* webpackChunkName: "component---src-pages-gdzie-zrobic-badania-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mapa-strony-js": () => import("./../../../src/pages/mapa-strony.js" /* webpackChunkName: "component---src-pages-mapa-strony-js" */),
  "component---src-pages-osrodek-zdrowia-regulamin-js": () => import("./../../../src/pages/osrodek-zdrowia-regulamin.js" /* webpackChunkName: "component---src-pages-osrodek-zdrowia-regulamin-js" */),
  "component---src-pages-osrodek-zdrowia-zapisy-js": () => import("./../../../src/pages/osrodek-zdrowia-zapisy.js" /* webpackChunkName: "component---src-pages-osrodek-zdrowia-zapisy-js" */),
  "component---src-pages-personel-osrodka-zdrowia-js": () => import("./../../../src/pages/personel-osrodka-zdrowia.js" /* webpackChunkName: "component---src-pages-personel-osrodka-zdrowia-js" */),
  "component---src-pages-pierwszy-rok-zycia-dziecka-ebook-js": () => import("./../../../src/pages/pierwszy-rok-zycia-dziecka-ebook.js" /* webpackChunkName: "component---src-pages-pierwszy-rok-zycia-dziecka-ebook-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-poradnia-medycyny-rodzinnej-js": () => import("./../../../src/pages/poradnia-medycyny-rodzinnej.js" /* webpackChunkName: "component---src-pages-poradnia-medycyny-rodzinnej-js" */),
  "component---src-pages-profilaktyka-i-diagnostyka-js": () => import("./../../../src/pages/profilaktyka-i-diagnostyka.js" /* webpackChunkName: "component---src-pages-profilaktyka-i-diagnostyka-js" */),
  "component---src-pages-pytania-js": () => import("./../../../src/pages/pytania.js" /* webpackChunkName: "component---src-pages-pytania-js" */)
}

